const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  confirmationEmail: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
}

class Firebase {
  constructor(app) {
    app.initializeApp(config)

    this.googleProvider = new app.auth.GoogleAuthProvider()
    this.app = app
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.storage = app.storage()

    if (location.hostname === "localhost" || location.hostname === "0.0.0.0") {
      this.auth.useEmulator("http://localhost:9099/")
      this.firestore.useEmulator("localhost", 8080)
    }

    this.uid = null
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    })

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doLogout = () => this.auth.signOut()

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        if (window.location.hostname === "localhost") {
          const token = await this.auth.currentUser.getIdToken(true)
          console.info(token)
        }

        this.uid = authUser.uid

        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data()

            // // default empty roles
            // if (!dbUser.roles) {
            //   dbUser.roles = {}
            // }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            }

            next(authUser)
          })
          .catch((error) => {
            console.info(error)
            console.info(this.firestore)
          })
      } else {
        this.uid = null
        fallback()
      }
    })

  // TODO: remove uid arg
  user = (uid) => this.firestore.collection(`users`).doc(uid || this.uid)

  users = (uid) => this.firestore.collection(`users`)

  billingDetails = (uid) => {
    return this.firestore
      .collection(`users`)
      .doc(uid || this.uid)
      .collection(`preferences`)
      .doc(`billing`)
  }

  orders = (uid) => {
    return this.firestore
      .collection(`orders`)
      .where("uid", "==", uid || this.uid)
  }
  order = (id) => this.firestore.collection(`orders`).doc(id)

  userPayments = (uid) =>
    this.firestore.collection(`users/${this.uid}/payments`)

  userMaps = (uid) => this.firestore.collection(`users/${uid || this.uid}/maps`)

  userGrids = (uid) =>
    this.firestore.collection(`users/${uid || this.uid}/geogrids`)

  userPowerLinks = (uid) =>
    this.firestore.collection(`users/${uid || this.uid}/power-links`)

  userProjects = (uid) =>
    this.firestore.collection(`users/${uid || this.uid}/projects`)

  powerLinks = () => this.firestore.collection("powerlinks")

  products = (type) =>
    this.firestore
      .collection(`products`)
      .where("type", "==", type)
      .orderBy("position", "asc")

  settings = () => this.firestore.collection(`settings`)
}

let firebase
function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database)
  }

  return firebase
}

export default getFirebase
